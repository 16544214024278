import { ApiUrl } from '@/constants';
import {
  type AddVoucherApiResponse,
  ApiErrorCode,
  type ApiErrorWithErrorCode,
  type ApiResponseError,
  type AvailablePhonenumbersResponse,
  type Brand,
  type CartApiResponse,
  type ConfirmOrderCreditDenied,
  type ConfirmOrderError,
  type ConfirmOrderPayload,
  type ConfirmOrderSuccess,
  type CreateCartParams,
  type CreateOrderPayload,
  type OrderResponse,
  type OrderStatusResponse,
  type PhonenumberGroup,
  type SalesFlowResponse,
  type ValdidatePortPhonenumbersResponse,
  type ValidateOrderPayload,
  type ValidateOrderResponse,
  type ValidateUserAndFetchInfoArgs,
  type ValidateUserAndFetchInfoResponse,
} from '@/types';
import { NextApiRequest } from 'next';

import { getGretchRetryPolicy, gretch, tokenizedApiCall } from '@/global/utils/Api';

export const createCart = async (payload: CreateCartParams) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/cart`;

  const { data, error } = await gretch<CartApiResponse, ApiResponseError>(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).json();

  if (data) return data.data;

  throw error;
};

export const getCart = async ({ cartId, userId }: { cartId: string | null; userId: string | null }) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/cart?cartId=${cartId}&userId=${userId}`;

  const { data, error } = await gretch<CartApiResponse | { data: false }, ApiResponseError>(url).json();

  if (data) return data.data;

  throw error;
};

export const getCartByIdServerSide = async ({ cartId, req }: { cartId: string; req: NextApiRequest }) => {
  const url = `${ApiUrl.cartApi}/cart/${cartId}`;
  try {
    const tokenizedResponse = await tokenizedApiCall(url, req);
    if (tokenizedResponse.ok) {
      const response = (await tokenizedResponse.json()) as CartApiResponse;
      return response.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const getCartByUserIdServerSide = async ({ userId, req }: { userId: string; req: NextApiRequest }) => {
  const url = `${ApiUrl.cartApi}/cart?userId=${userId}`;
  try {
    const tokenizedResponse = await tokenizedApiCall(url, req);
    if (tokenizedResponse.ok) {
      const response = (await tokenizedResponse.json()) as CartApiResponse;
      return response.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const addVoucher = async ({ cartId, voucherCode }: { cartId: string; voucherCode: string }) => {
  const url = `${ApiUrl.webClientApi}/new-checkout/voucher`;

  const { data, error } = await gretch<AddVoucherApiResponse, ApiResponseError>(url, {
    method: 'POST',
    body: JSON.stringify({ voucherCode, cartId }),
  }).json();

  if (data) return data.data;

  throw error;
};

export const setUserIdToCart = async ({ cartId, userId }: { cartId?: string; userId: string }) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/cart?cartId=${cartId}&userId=${userId}`;

  const { data, error } = await gretch<CartApiResponse, ApiResponseError>(url, { method: 'PUT' }).json();

  if (data) return data.data;

  throw error;
};

export const validateUserAndFetchInfo = async ({ ownerId, email, brand }: ValidateUserAndFetchInfoArgs) => {
  const url = `${ApiUrl.webClientApi}/new-checkout/validate?ownerId=${encodeURIComponent(
    ownerId
  )}&email=${encodeURIComponent(email)}&brand=${brand}`;

  const { data, error } = await gretch<ValidateUserAndFetchInfoResponse, ApiResponseError>(url).json();

  if (data) return data.data;

  throw error;
};

export const validateUserAddress = async ({ ownerId, brand }: { ownerId: string; brand: Brand }) => {
  const url = `${ApiUrl.webClientApi}/new-checkout/address?ownerId=${ownerId}&brand=${brand}`;

  const { data, error } = await gretch<ValidateUserAndFetchInfoResponse, ApiResponseError>(url).json();

  if (data) return data.data;

  throw error;
};

export const fetchAvailablePhonenumbers = async (customerContext: Brand, group: PhonenumberGroup) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/order/phonenumbers?customerContext=${customerContext}&group=${group}`;

  const { data, error } = await gretch<AvailablePhonenumbersResponse, ApiResponseError>(url).json();

  if (data) return data.data;

  throw error;
};

export const validatePortPhonenumber = async (phonenumber: string) => {
  const url = `${
    ApiUrl.webClientApi
  }/protected/new-checkout/order/phonenumbers/numberportvalidation?phonenumber=${encodeURIComponent(phonenumber)}`;

  const { data, error } = await gretch<ValdidatePortPhonenumbersResponse, ApiResponseError>(url).json();

  if (data) return data.data;

  throw error;
};

export const validateOrder = async (payload: ValidateOrderPayload) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/order/validate`;

  const { data, error } = await gretch<ValidateOrderResponse, ApiResponseError>(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).json();

  if (data) return data.data;

  throw error;
};

export const createOrder = async (payload: CreateOrderPayload) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/order`;
  const formattedPayload = {
    ...payload,
    ownerId: payload.ownerId.replaceAll('-', ''),
  };

  const { data, error } = await gretch<OrderResponse, ApiErrorWithErrorCode>(url, {
    method: 'POST',
    timeout: 20000,
    body: JSON.stringify(formattedPayload),
  }).json();

  if (data) return data.data;

  throw error;
};

export const getOrderServerSide = async ({ orderId, req }: { orderId: string; req: NextApiRequest }) => {
  const url = `${ApiUrl.orderApi}/orders/${orderId}`;

  const tokenizedResponse = await tokenizedApiCall(`${url}`, req);
  if (tokenizedResponse.ok) {
    const response = (await tokenizedResponse.json()) as OrderResponse;
    return response.data;
  }

  const error = tokenizedResponse.body;
  throw error;
};

export const getOrder = async ({ orderId }: { orderId: string }) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/order?orderId=${orderId}`;

  const { data, error } = await gretch<OrderResponse, ApiResponseError>(url).json();

  if (data) return data.data;

  throw error;
};

export const confirmOrder = async (
  orderId: string,
  payload: ConfirmOrderPayload
): Promise<ConfirmOrderSuccess | ConfirmOrderCreditDenied> => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/order/confirm?orderId=${orderId}`;

  const { status, error } = await gretch<number, ConfirmOrderError>(url, {
    timeout: 20000,
    method: 'PATCH',
    body: JSON.stringify({ ...payload }),
  }).json();

  if (status === 200 || status === 204) {
    return {
      confirmed: true,
      order: null,
    };
  }
  if (error?.data.message?.metaData.ERROR_CODE === ApiErrorCode.ERROR_PAYMENT_METHOD_INVOICE_NOT_ALLOWED) {
    return {
      confirmed: false,
      order: error.data.message.data,
    };
  }

  throw error;
};

export const confirmPayment = async (adyenMerchantReference: string): Promise<boolean | void> => {
  const url = `${ApiUrl.webClientApi}/new-checkout/payment/confirm`;

  const { status, error } = await gretch<number, ApiResponseError>(url, {
    method: 'PUT',
    body: JSON.stringify({ merchantReference: adyenMerchantReference }),
  }).json();

  if (status === 204) return true;

  throw error;
};

export const getOrderStatus = async (orderId: string) => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/order/status?orderId=${orderId}`;

  const { data, error } = await gretch<OrderStatusResponse, ApiResponseError>(url, {
    retry: getGretchRetryPolicy({ methods: ['GET'] }),
  }).json();

  if (data) return data.data;

  throw error;
};

export const getOrderRowPdf = async (orderId: string, orderRowId: string): Promise<string> => {
  const url = `${ApiUrl.webClientApi}/protected/new-checkout/order/pdf?orderId=${orderId}&orderRowId=${orderRowId}`;

  const { data, error } = await gretch<Blob, ApiResponseError>(url).blob();

  if (data) {
    const octetStream = new Uint8Array(await data.arrayBuffer());
    const base64 = Buffer.from(octetStream).toString('base64');
    return base64;
  }

  throw error;
};

export const getSalesFlowData = async (personalNumber: string, isB2b: boolean) => {
  const url = `${ApiUrl.proxy.orderApi}/salesflow${isB2b ? '/business' : ''}/${personalNumber}`;

  const { data, error } = await gretch<SalesFlowResponse, string>(url).json();

  return { data: data?.data, errorKey: error };
};

export const validateEmailSignup = async (email: string, signal?: AbortSignal) => {
  const url = `${ApiUrl.proxy.orderApi}/customers/cansignup?email=${encodeURIComponent(email)}`;

  const res = await fetch(url, {
    signal,
  });
  const data = await res.json();

  return { canSignUp: Boolean(data?.data?.canSignUp) };
};

export const validatePortPhonenumberSignup = async (phonenumber: string) => {
  const url = `${ApiUrl.proxy.orderApi}/phonenumbers/numberportvalidation/${phonenumber}`;

  const { data, error } = await gretch<ValdidatePortPhonenumbersResponse, string>(url).json();

  return { data: data?.data, errorKey: error };
};
