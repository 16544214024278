import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ContentWidth } from '@/global/style/breakpoint';
import { BorderRadius, shadows, spacing } from '@/global/style/variables';

export const ToastNoticeWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  box-shadow: ${shadows.default};
  border-radius: ${BorderRadius.Large};
  max-width: ${ContentWidth.sm};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${spacing.x15};
  right: ${spacing.x15};
  border-radius: ${BorderRadius.Small};
  padding: 2px;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
