import NoticeField from '@/newComponents/NoticeField/NoticeField';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import toast, { Toast } from 'react-hot-toast';

import { spacing } from '@/global/style/variables';

import { CloseButton, ToastNoticeWrapper } from './ToastNotice.styled';

const animation = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, scale: 0 },
};

export type ToastNoticeProps = {
  toastId: Toast['id'];
  visible: Toast['visible'];
  preset: NoticeFieldPreset;
  dataTestId: string;
  header?: string;
  text?: string;
};

const ToastNotice = (props: ToastNoticeProps) => {
  const { header, text, toastId, preset, dataTestId, visible } = props;

  return (
    <ToastNoticeWrapper variants={animation} initial="hidden" animate={visible ? 'visible' : 'exit'}>
      <CloseButton onClick={() => toast.dismiss(toastId)}>
        <SvgIcon icon="cross" size="small" />
      </CloseButton>
      <NoticeField
        data-testid={dataTestId}
        preset={preset}
        header={header}
        text={text}
        showBorder={false}
        customPadding={`${spacing.x3} ${spacing.x8} ${spacing.x25} ${spacing.x4}`}
      />
    </ToastNoticeWrapper>
  );
};

export default ToastNotice;
