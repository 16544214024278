/*
  To ensure that the indened value was found.
  Example:
    const matchingItem = itemArray.find((item) => item.id === someId);

  matchingItem can in theory always be undefined. If the itemArray was empty to start with, or if no matching item was returned
  If we want to make sure that we always find something,
  instead of having to wrap the code following the array.find in a if-statement, we can use this ensure function. If the return from array.find is undefined, a throw will be triggered.

  https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefined/54738437#54738437
*/

export function ensure<T>(argument: T | undefined | null, message = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}
