import ToastNotice, { ToastNoticeProps } from '@/newComponents/ToastNotice/ToastNotice';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

type ToastNoticeSettings = Omit<ToastNoticeProps, 'toastId' | 'visible'> & { duration?: number };

const useToastNotice = () => {
  const toaster = useCallback(
    (settings: ToastNoticeSettings) =>
      toast.custom(
        (t) => (
          <ToastNotice
            visible={t.visible}
            toastId={t.id}
            dataTestId={settings.dataTestId}
            preset={settings.preset}
            header={settings.header}
            text={settings.text}
          />
        ),
        {
          duration: settings.duration || 5000,
        }
      ),
    []
  );

  return toaster;
};

export default useToastNotice;
